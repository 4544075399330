<template>
    <v-footer class="py-4 footer" height="200">
        <v-container class="mx-auto">
            <v-row>
                <v-col cols="9">
                    <base-btn v-for="(item, i) in items" :key="i" :href="item.href" :title="item.title" class="ml-2 mr-0 raise" target="_blank">
                        <v-icon large :color="item.color" v-text="item.icon" />
                    </base-btn>
                </v-col>

                <v-spacer />

                <base-btn class="mr-0 raise" title="Wróć na górę" @click="$vuetify.goTo(0)">
                    <v-icon large>mdi-chevron-up</v-icon>
                </base-btn>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: "CoreFooter",

    data: () => ({
        items: [
            {
                href: "https://www.instagram.com/natashalucas.books/",
                icon: "mdi-instagram",
                title: "Odwiedź Instagram",
                color: "rgb(188, 42, 141)",
            },
            {
                href: "https://www.facebook.com/NatashaLucas.Books/",
                icon: "mdi-facebook",
                title: "Odwiedź Facebook",
                color: "rgb(59, 89, 152);",
            },
            {
                href: "mailto:natasha-lucas@wp.pl?subject=Kontakt ze strony Natasha Lucas",
                icon: "mdi-email-outline",
                title: "Wyślij e-mail",
                color: "black",
            },
        ],
    }),
};
</script>
<style lang="scss" scoped>
.raise:hover,
.raise:focus {
    box-shadow: 0 0.5em 0.5em -0.4em;
    transform: translateY(-0.25em);
    background-color: rgba(255, 255, 255, 0.034);
    .mdi-email-outline {
        color: rgb(170, 82, 5) !important;
    }
    .mdi-facebook {
        color: #4267b2 !important;
    }
    .mdi-instagram {
        color: #dc00e4 !important;
    }
}
.footer {
    background: rgba(246, 249, 254, 0) !important; /* Old browsers */
    background: -moz-linear-gradient(top, rgba(246, 249, 254, 0) 5%, rgba(226, 240, 254, 1) 100%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(246, 249, 254, 0) 5%, rgba(226, 240, 254, 1) 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(246, 250, 254, 0) 5%, rgba(226, 240, 254, 1) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
